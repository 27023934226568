import { useEffect, useState } from 'react'
import AuthProiver from '../../../public/components/AuthProvier'
import Loading from '../../../public/components/Loading'
import api from '../config/api'
import BaLayout from './BaLayout'
import Redirect from '../../../public/components/Redirect'

export default (props: any) => {

    const [data, setData] = useState<any | null>(null);

    const setLoginData = (value:any)=>{

        setData(value);
    }

    useEffect(() => {

     
        api("login/current").get({}).then(ret => {

            if (ret?.code == 200) {
                setData(ret.data);
            }

        });

    }, []);

    if (!data) {

        return (
            <div style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <Loading />
            </div>
          
        )
    }

  if (!data) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loading />
      </div>
    )
  }

  return (
    <AuthProiver login={{ login: data, setLogin: setData }}>
      <>
        {data?.bindGoogleToken? <BaLayout>{props.children}</BaLayout>:<Redirect to={`/bind/google-token/${data.userName}`}/>}
     
      </>
    </AuthProiver>
  )
}
