import { Component, FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
export type PageRoute = {

    path: string,
    layout?:any| Component | FunctionComponent,
    component?:  any | Component | FunctionComponent,
    children?: PageRoute[]

}

export type Mod = {

    routes: PageRoute[],
    index: React.ReactNode | null | undefined

}



export const RenderRoute = (route: PageRoute, index: number) => {

    const hasChildren = (route.children && route.children.length > 0) ? true : false;
    const children: PageRoute[] | undefined = route.children;
    const Layout: any = route.layout ? route.layout : <></>;
    const RouteComponent: any = route.component ? route.component : <></>;
    return (
        <Route key={route.path} path={route.path} element={

            hasChildren ? <Layout>
                <Routes>
                    {children?.map((it, i) => RenderRoute(it, i))}
                </Routes>
            </Layout> : <RouteComponent />
        } >
        </Route>
    )


}