import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Spin, SpinProps } from "antd";
import { FC } from "react";


const Loading: FC<SpinProps> = (props) => {




    return (
        <Layout style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", flex: 1, backgroundColor: "#fff" }}>

            <Spin indicator={<LoadingOutlined />} style={{ fontSize: 32 }} {...props}/>

        </Layout>
    )


}

export default Loading;

