import React, { createContext, useContext, useEffect, useState } from "react";
import moment  from "moment";



export const setDefaultTimezone = (defaultTimezone) => {
    moment.tz.setDefault(defaultTimezone);
};



const TimezoneContext = createContext({
    timezone: null,
    setTimezone: () => { }
});

export const useTimezone = () => {

    const contenxt = useContext(TimezoneContext);

    return contenxt?.timezone;

}

export const useSetTimezone = () => {
    const contenxt = useContext(TimezoneContext);
    return (timezone) => {

        contenxt?.setTimezone(timezone);

    }
}



const TimezoneProiver = ({ children, timezone }) => {


    return (
        <TimezoneContext.Provider value={timezone}>
            {children}
        </TimezoneContext.Provider>
    )

}


export default TimezoneProiver;