import React, { createContext, FC, useContext } from "react";

type ThemeProps = {
    dark:boolean,
    setDark:(dark:boolean)=>void
}|null

const ThemeContext = createContext<ThemeProps>(null);

export const useIsDark = ()=>{

    const contenxt = useContext(ThemeContext);

    return contenxt?.dark;

}

export const useSetDark = ()=>{
    const contenxt = useContext(ThemeContext);
    return (dark:boolean)=>{
     contenxt?.setDark(dark)
    }
}




type ThemeProiverProps = {
    theme:ThemeProps,
    children?:React.ReactNode
}

const ThemeProiver:FC<ThemeProiverProps> =  ({children,theme})=>{

        return (
            <ThemeContext.Provider value={theme}>
                {children}
            </ThemeContext.Provider>
         
        )

}


export default ThemeProiver;