import moment from "moment";


// dayjs.


export const getTimezone = ()=>{
    const timeZone = localStorage.getItem("default-tz-timezone");

    if(timeZone){
        return timeZone;
    }

    return moment.tz.guess();

    
}

export const getDateTimeValue:number|any =(date:moment.Moment) => {

   
   

     return date?.tz()?.valueOf();

    
   


};

export const dayjsTz:moment.Moment|any = (time:Date) =>{

    return moment(time).tz(getTimezone());

}

export const getTz = ()=>{

    return  moment().tz(getTimezone()).format("Z");

}


