
const strings = "qertyuiopasdfghjklzxcvbnm".split("");

/**
 * 生成数据数字
 * @param max
 * @returns
 */
export const randomNumber = (max:number) =>{
    return Math.floor( Math.random()*max);
}

/**
 * 生成随机字符串
 * @param len
 */
export const randomString = (len:number)=>{

   return new Array(len).map(i=>strings[randomNumber(strings.length)]).join("");

}

/**
 * 生成随机数字
 * @param len
 * @returns
 */
export const randomNumberString = (len:number)=>{

    return new Array(len).map(i=>randomNumber(len)).join("");

}

