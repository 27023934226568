
import { Component } from 'react';



const importFunc = (importFunc) => {
    return class AsyncComponent extends Component {

        state = {
            component: null,
        }
    
        componentDidMount() {
            importFunc.then(component => {
                this.setState({
                    component: component.default,
                })
            })
        }
    
    
    
        render() {
            const LayoutComponent = this.state.component;
    
            if (!LayoutComponent) {
                return null;
            }
    
            return <LayoutComponent {...this.props} />
        }
    }
}

export default importFunc;