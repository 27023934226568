import { type } from "os";
import React, { FC, useEffect } from "react";
import { useNavigate,useLocation } from "react-router";


type RedirectProps = {
   to:string
}



const Redirect:FC<RedirectProps> = ({to})=>{

    const location = useLocation();
    const navigate = useNavigate();

   

    useEffect(()=>{
        navigate(to);
    },[])

    return (
        <>
        </>
    )
}

export default Redirect;