type ConfigEnv = {
  baseApi: string
}

const configValues: any = {
  development: {
    baseApi: '//localhost:5301',
  },
  production: {
    baseApi: '//api.ba.kabank.cc',
  },
}

const env: ConfigEnv = configValues[`${process.env.NODE_ENV}`]

export default env
