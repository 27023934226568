import Async from '../../../public/components/Async'
import { PageRoute } from '../../../public/module'
import AdminLayout from '../layout/AdminLayout'
import ContentLayout from '../layout/ContentLayout'

const routes: PageRoute[] = [
  {
    path: '/login',
    component: Async(import('../page/login/index')),
  },
  {
    path: '/bind/google-token/:user',
    component: Async(import('../page/login/BindGoogleToken'))
  },
  {
    path: '/*',
    layout: AdminLayout,
    children: [
      {
        path: '/',
        component: Async(import('../page/home/index')),
      },
      {
        path: '/sys/*',
        layout: ContentLayout,
        children: [
          { path: '/menu', component: Async(import('../page/sys/menu')) },
          { path: '/role', component: Async(import('../page/sys/role')) },
          { path: '/user', component: Async(import('../page/sys/user')) },
          { path: '/config', component: Async(import('../page/sys/config')) },
        ],
      },
      {
        path: '/center/:key',
        component: Async(import('../page/center/index')),
      },
      {
        path: '/group/*',
        layout: ContentLayout,
        children: [
          { path: '/list', component: Async(import('../page/group/index')) },
         
        ],
      },
      {
        path: '/message/*',
        layout: ContentLayout,
        children: [
          { path: '/list', component: Async(import('../page/message/index')) },
         
        ],
      },
      {
        path: '/lottery/*',
        layout: ContentLayout,
        children: [
          { path: '/list', component: Async(import('../page/lottery/index')) },
         
        ],
      },
      {
        path: "/robot/*",
        layout: ContentLayout,
        children: [
          { path: "/list", component: Async(import("../page/robot/index")) },
        
        ]
      },
      {
        path: "/setting/*",
        layout: ContentLayout,
        children: [
          { path: "/platform", component: Async(import("../page/setting/platform")) },
          { path: "/token", component: Async(import("../page/setting/token")) },
          { path: "/paygateway", component: Async(import("../page/setting/pay-gateway")) },
        ]
      },
      {
        path: "/pawn/comb/*",
        layout: ContentLayout,
        children: [
          { path: "/list", component: Async(import("../page/pawn/comb")) },
        
        ]
      },
      {
        path: "/order/*",
        layout: ContentLayout,
        children: [
          { path: "/payin", component: Async(import("../page/order/payin")) },
          { path: "/payout", component: Async(import("../page/order/payout")) },
        ]
      },
      {
        path: "/statistics/*",
        layout: ContentLayout,
        children: [
          { path: "/order/payin", component: Async(import("../page/statistics/payin")) },
          { path: "/order/payout", component: Async(import("../page/statistics/payout")) },
        ]
      },
      {
        path: '/log/*',
        layout: ContentLayout,
        children: [
          {
            path: '/opt',
            component: Async(import('../page/log/optRecods')),
          },
          {
            path: '/login',
            component: Async(import('../page/log/loginRecords')),
          },
        ],
      },
      {
        path: '/user/*',
        layout: ContentLayout,
        children: [
          {
            path: '/list',
            component: Async(import('../page/user/users')),
          },
          {
            path: '/match/mch',
            component: Async(import('../page/user/user-match-mch')),
          },
        ],
      },
      {
        path: '/merchant/*',
        layout: ContentLayout,
        children: [
          {
            path: '/list',
            component: Async(import('../page/user/merchants')),
          },
          {
            path:"/receive/account",
            component:Async(import("../page/merchant/receive-account"))
          },
          {
            path:"/group/bind",
            component:Async(import("../page/merchant/group-bind"))
          }
        ],
      },
      {
        path: '/money/*',
        layout: ContentLayout,
        children: [
          {
            path: '/record',
            component: Async(import('../page/money/index')),
          },
        ],
      },
    ],
  },
]

export default routes
