import React, { createContext, FC, useContext } from "react";

type LoginProps = {
    login:any,
    setLogin:(login:any)=>void
}|null

const AuthContext = createContext<LoginProps>(null);

export const useGetLogin:any = ()=>{

    const contenxt = useContext(AuthContext);

    return contenxt?.login;

}

export const useSetLogin = ()=>{
    const contenxt = useContext(AuthContext);
    return (values:any)=>{
     contenxt?.setLogin(values);
    }
}

type AuthProiverProps = {
    login:LoginProps,
    children?:React.ReactNode
}

const AuthProiver:FC<AuthProiverProps> =  ({children,login})=>{
        return (
            <AuthContext.Provider value={login}>
                {children}
            </AuthContext.Provider>
        )

}


export default AuthProiver;