import { Layout } from "antd";

export default (props: any) => {


 


    return (
       
        <Layout.Content
            style={{
                padding: 15,
                // backgroundColor:  "#ffff",
                // margin: "10px 10px",
                minHeight: "calc(100vh - 60px)",
            }}
        >{props.children}</Layout.Content>

    )


}