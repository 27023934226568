import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';



type ApiRespData<T> = {


    code:number,
    data:T,
    msg:string

};




type Callback<T> =(callback:T)=>void;

type Error ={
    code:number,
    message:string
}

export default class Api {



    private defaultConfig:Callback<AxiosRequestConfig> = ()=>{};

    private respCallback:Callback<AxiosResponse> = ()=>{};

    private errorCallback:Callback<Error> = ()=>{};

    private optionsConfig:AxiosRequestConfig = {};

     
    constructor(public baseUrl:string,public path:string){
       
    }


    config(config:Callback<AxiosRequestConfig>):Api{
        this.defaultConfig = config;
        return this;
    }

    onResponse(callabck:Callback<AxiosResponse>):Api{

        this.respCallback = callabck;

        return this;
    }

    onError(error:Callback<Error>):Api{
        this.errorCallback = error;
    
        return this;
    }
    



    private getRequestUrl():string{

        return `${this.baseUrl}/${this.path}`;
    }

    private axiosRequest(options:AxiosRequestConfig<any>):AxiosPromise<AxiosResponse>{
        
      
        options.url = options.url?options.url:this.getRequestUrl();
        
        this.defaultConfig(options);

        // return new Promise()
        return axios(options).then(ret=>{
            this.respCallback(ret);
            return ret;
        }).catch(err=>{
            this.errorCallback({message:err.message,code:err.response?err.response.status:-1});
            // return Promise.reject(err);
            return err;
        });
    }

    axios(options:AxiosRequestConfig):AxiosPromise<any>{
        options.url = options.url?options.url:this.getRequestUrl();
        this.defaultConfig(options);
        return axios(options)
    }
   
    request(options:AxiosRequestConfig<any>):Promise<ApiRespData<any>>{
      
        return this.axiosRequest(options).then(ret=>{

            const data:any = {
                ...ret.data
            }

            return data;
        });
    }


    /**
     * 下载文件
     * @param params 
     * @param fileName 
     * @returns 
     */
    download(params:any,fileName:string):Promise<any>{
        return this.axios({
            url:this.getRequestUrl(),
            method:"GET",
            params:params
        }).then(ret=>{
            // console.log(ret.headers);
            if(ret.headers["content-type"].indexOf("application/json") != -1){
                return ret.data;
            }
            const blob = new Blob([ret?.data], { type: 'application/octet-stream' })
            const blobURL = window.URL.createObjectURL(blob)
            // 创建a标签，用于跳转至下载链接

            window.open(blobURL,"_blank");
            // const tempLink = document.createElement('a')
            // tempLink.style.display = 'none'
            // tempLink.href = blobURL;
            // tempLink.setAttribute('download', fileName);
            // // 兼容：某些浏览器不支持HTML5的download属性
            // if (typeof tempLink.download === 'undefined') {
            //     tempLink.setAttribute('target', '_blank')
            // }
            // // 挂载a标签
            // document.body.appendChild(tempLink)
            // tempLink.click()
            // document.body.removeChild(tempLink)
            // // 释放blob URL地址
            // window.URL.revokeObjectURL(blobURL);
            return {code:200,msg:"下载成功"}
        })
    }


    get(params:any|undefined):Promise<ApiRespData<any>>{
       
        return this.request({params:params,method:"GET"});
    }

    
    post(params:any|undefined):Promise<ApiRespData<any>>{
        return this.request({data:params,method:"POST"})
    }

    put(params:any|undefined):Promise<ApiRespData<any>>{
        return this.request({data:params,method:"PUT"})
    }

    delete(params:any|undefined):Promise<ApiRespData<any>>{
        return this.request({data:params,method:"DELETE"})
    }

   

}

